import React, { useState, useRef } from "react";
import "./BillCalc.css";
import ErrorBox from "./ErrorBox";
import Results from "./Results";

const BillCalc = () => {
  const [clear, setClear] = useState(false);

  const varme = useRef(null);
  const elforb = useRef(null);
  const faktura = useRef(null);

  const [errorMessage, setErrorMessage] = useState("");
  const [heatCost, setHeatCost] = useState(0);

  const Clear = () => {
    setClear(true);
    setErrorMessage("");
  };

  const ClearAll = () => {
    setClear(true);
    setErrorMessage("");
    setHeatCost(0);

    varme.current.value = "";
    elforb.current.value = "";
    faktura.current.value = "";
  };

  const inputsValidate = () => {
    if (varme.current.value <= 0) {
      setErrorMessage("Värmeförbrukningens värde stämmer inte.");
      return false;
    }

    if (elforb.current.value <= 0) {
      setErrorMessage("Totala elförbrukningens värde stämmer inte.");
      return false;
    }

    if (faktura.current.value <= 0) {
      setErrorMessage("Fakturabeloppets värde stämmer inte.");
      return false;
    }

    if (parseInt(elforb.current.value) < parseInt(varme.current.value)) {
      setErrorMessage(
        "Totala elförbrukningens värde borde vara mer än enbart värmeförbrukningen."
      );
      return false;
    }

    return true;
  };

  const Calculate = () => {
    if (clear) setClear(false);

    if (!inputsValidate()) {
      return;
    }

    const percent = varme.current.value / elforb.current.value;

    setHeatCost(percent * faktura.current.value);
  };

  return (
    <div className="flex flex-col w-full xl:w-2/5 justify-center lg:items-start overflow-y-hidden">
      <div className="form">
        <ErrorBox showMessage={errorMessage ? true : false} message={errorMessage} />

        <div className="mb-4">
          <label>Värmeförbrukning:</label>
          <input
            ref={varme}
            type="number"
            min="0"
            step="1"
            placeholder="Värme i kWh"
            onChange={Clear}
          />{" "}
          kWh
        </div>
        <div className="mb-4">
          <label>Total elförbrukning:</label>
          <input
            ref={elforb}
            type="number"
            min="0"
            step="1"
            placeholder="Total elförbrukning i kWh"
            onChange={Clear}
          />{" "}
          kWh
        </div>
        <div className="mb-4">
          <label>Belopp på faktura:</label>
          <input
            ref={faktura}
            type="number"
            min="0"
            placeholder="Total belopp på faktura i kr"
            onChange={Clear}
          />{" "}
          kr
        </div>

        <div className="flex items-center justify-between pt-4">
          <button onClick={Calculate}>Beräkna</button>
          <button onClick={ClearAll}>Rensa</button>
        </div>
      </div>

      <Results heatCost={heatCost} />
    </div>
  );
};

export default BillCalc;
