import React from "react";

type props = {
  heatCost: number;
};

function Results({ heatCost }: props) {
  return (
    <div
      className={
        "relative block bg-gray-900 opacity-75 w-full shadow-lg rounded-lg px-8 pt-6 pb-8 mb-4 transform transition-all duration-150 ease-out " +
        (heatCost > 0 ? " scale-100" : " scale-0")
      }
    >
      <strong className="text-xl bg-clip-text text-transparent bg-gradient-to-r from-green-400 via-pink-500 to-purple-500">
        Resultat
      </strong>

      <h5 className="mt-4 text-lg font-bold">
        Värmekostnad: {Math.round((heatCost + Number.EPSILON) * 100) / 100} kr
      </h5>
      <h5 className="mt-4 text-lg font-bold">
        Per person: {Math.round((heatCost / 2 + Number.EPSILON) * 100) / 100} kr
      </h5>
    </div>
  );
}

export default Results;
