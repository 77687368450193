import React from "react";
import BillCalc from "./components/BillCalc";
import Nav from "./components/Nav";
import Footer from "./components/Footer";

function App() {
  return (
    <div className="h-full">
      <Nav />

      <div className="container pt-24 md:pt-36 mx-auto flex flex-wrap flex-col md:flex-row items-center">
        <BillCalc />
        <Footer />
      </div>
    </div>
  );
}

export default App;
