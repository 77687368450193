import React from "react";

function Footer() {
    return (
        <div className="w-full pt-16 pb-6 text-sm text-center md:text-left fade-in">
          <a className="text-gray-500 no-underline hover:no-underline" href="https://markusekegren.se" target="_blank" rel="noreferrer noopener">Made by Markus Ekegren &copy; 2022</a>
        </div>
    )
}

export default Footer;